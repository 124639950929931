/* Prevent horizontal scrolling */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; /* Ensure the body and html fill the entire viewport */
  overflow-x: hidden; /* Prevent horizontal scroll */
  overflow-y: auto; /* Allow vertical scrolling only if needed */
}

/* Reset default margin and padding for the App */
.App {
  text-align: center;
}

/* Light Mode */
body.light-mode {
  background-color: #ffffff;
  color: #333333;
}

/* Dark Mode */
body.dark-mode {
  background-color: #121212;
  color: #f5f5f5;
}

h1 {
  font-size: calc(30px + 1vmin);
}

h2 {
  font-size: calc(30px + 1vmin);
}

/* App button styles */
.App-button {
  background-color: #16306F;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 1em 2em;
  font-size: calc(10px + 1vmin);
  cursor: pointer;
  margin: 1em;
  transition: background-color 0.3s;
}

.App-button:hover {
  background-color: #3A98FC;
}

/* App-header styles */

.App-link {
  color: #3A98FC;
  text-decoration: none;
  font-size: calc(12px + 1vmin);
}

/* Menu button styles */
.menu-toggle {
  position: absolute; /* Fixed to the top-left corner */
  top: 2px; /* 2px from the top edge */
  left: 2px; /* 2px from the left edge */
  background-color: #16306F;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1em;
  cursor: pointer;
  z-index: 12; /* Ensure it stays on top */
  margin-bottom: 0px; /* No space below the button */
  height: 50px; /* Set a fixed height for the button */
}

/* Expandable menu styles */
.expandable-menu {
  position: absolute; /* Keeps the menu fixed in the view */
  top: 50px; /* Adjust to be just below the menu button */
  left: 2px; /* Adjust to be aligned with the menu button */
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Lower than menu toggle button, but still above other elements */
  width: 250px; /* Set menu width */
  max-width: 100%; /* Prevent overflow if the screen is too small */
  max-height: 90vh; /* Prevent menu from overflowing the viewport */
  overflow-y: auto; /* Allow vertical scrolling if content is too long */
}

.expandable-menu.light-mode {
  background-color: #f8f3f3;
}

.expandable-menu.dark-mode {
  background-color: #333333;
}

.expandable-menu ul {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.expandable-menu li {
  margin-bottom: 5px;
}

.button {
  cursor: pointer;
  padding: 1em;
  background-color: #3A98FC;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 100%;
  text-align: left;
}

.button:hover {
  background-color: #16306F;
}

/* Adjust pages to allow scrolling */
.home-page, .upload-page, .dashboard-page {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0;
  margin-top: 0;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh; /* Ensure the page can grow */
  overflow-y: auto; /* Allow vertical scrolling */
}

.home-page h1 {
  color: #323130;
  font-size: 2rem;
  margin-bottom: 10px;
}

.upload-page h2, .dashboard-page h2 {
  color: white;
  font-size: 2rem;
  margin-bottom: 10px;
}

.home-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  margin-bottom: 20px;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  color: white;
  font-size: 1.1rem;
  line-height: 1.6;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

/* Layout for Actionable Insights, Patient Impact, Economic Impact, Quality Improvement */
.actionable-insights {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  gap: 20px;
}

.actionable-insights h2 {
  font-size: 1.5rem;
  text-align: center;
  flex: 1;
  margin: 0;
}

/* Content Section for "Your Need" and "Features and Capabilities" */
.content-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

/* Left side - Your Need */
.your-need, .your-need p {
  color: black;
  flex: 1;
  background-color: transparent;
  backdrop-filter: blur(10px); /* Apply a blur effect behind the content */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Right side - Features and Capabilities */
.features-capabilities {
  color: black;
  flex: 1;
  background-color: transparent;
  backdrop-filter: blur(50px); /* Apply a blur effect behind the content */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Style for the list */
.features-capabilities ul {
  list-style-type: disc; /* Default bullet style */
  margin-left: 0; /* Remove any default margin */
  padding-left: 20px; /* Set padding so bullets align with the text */
}

.features-capabilities li {
  font-size: 1.1rem; /* Adjust font size */
  margin-bottom: 10px; /* Add space between items */
  line-height: 1.6; /* Line spacing for readability */
  padding-left: 5px; /* Slight padding to ensure the text aligns with the bullet */
}

/* Optional: Adjust the list items so they are indented the same amount as the bullets */
.features-capabilities ul {
  padding-left: 20px; /* Ensure bullets and text align by adding left padding */
}

/* Responsive adjustment if needed */
@media (max-width: 768px) {
  .features-capabilities ul {
    padding-left: 15px; /* Adjust padding on smaller screens */
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .actionable-insights {
    flex-direction: column;
    align-items: center;
  }

  .actionable-insights h2 {
    margin-bottom: 10px;
    text-align: center;
  }

  .content-container {
    flex-direction: column;
    gap: 20px;
  }

  .your-need, .features-capabilities {
    width: 100%;
    padding: 15px;
  }

  /* Responsive adjustment if needed */
  @media (max-width: 768px) {
    .features-capabilities ul {
      padding-left: 15px; /* Adjust padding on smaller screens */
    }
  }
}

.welcome-message {
  position: absolute;
  top: 1vh;
  right: 2vw;
  color:#0056b3;
  background-color: transparent;
  font-size: calc(12px + 1vmin);
  border: none;
  cursor: pointer;
}

/* Ensure the main area can scroll */
main {
  background-color: #636D78;
  padding: 0;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Allow vertical scrolling */
}

/* Header buttons container */


/* Menu button styles */
.menu-toggle {
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1em;
  cursor: pointer;
  margin-right: 1em;
}

/* Sign out button */
.sign-out-button {
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1em;
  cursor: pointer;
  margin-left: 1em;
  transition: background-color 0.3s;
}

.sign-out-button:hover {
  background-color: #c9302c;
}

/* Media Queries for additional responsiveness */
@media (max-width: 1024px) {
  .App-header {
    font-size: calc(12px + 1.5vmin);
  }

  .App-button {
    padding: 0.8em 1.6em;
    font-size: calc(8px + 1.5vmin);
  }

  .menu-toggle, .sign-out-button {
    padding: 0.8em;
    font-size: calc(8px + 1vmin);
  }

  .header-buttons {
    margin-top: 2vh;
  }

  .home-page h1, .upload-page h2, .dashboard-page h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .App-logo {
    height: 15vmin;
  }

  .home-page h1, .upload-page h2, .dashboard-page h2 {
    font-size: 1.5rem;
  }

  .welcome-message {
    font-size: calc(10px + 1vmin);
  }

  .header-buttons {
    flex-direction: column;
    align-items: stretch;
    margin-top: 1vh;
  }
}

/* For mobile screens, adjust position */
@media (max-width: 768px) {
  .expandable-menu {
    width: 100%; /* Full width on mobile */
    max-width: 100%; /* Avoid overflow */
  }
}

/* Adjust for dark and light mode */
.expandable-menu.light-mode {
  background-color: #ffffff;
}

.expandable-menu.dark-mode {
  background-color: #333333;
}

@media (max-width: 480px) {
  .App-logo {
    height: 10vmin;
  }

  .App-button {
    padding: 0.8em 1.2em;
    font-size: calc(6px + 2vmin);
  }

  .menu-toggle, .sign-out-button {
    padding: 0.8em;
    font-size: calc(6px + 2vmin);
  }

  .home-page, .upload-page, .dashboard-page {
    padding: 15px;
  }

  .home-page h1, .upload-page h2, .dashboard-page h2 {
    font-size: 1.2rem;
  }

  .welcome-message {
    font-size: calc(8px + 1vmin);
  }
}

/* Wrapper container will now stack children vertically */
.wrapper-container {
  display: flex;                  /* Use Flexbox for layout */
  flex-direction: column;         /* Stack items vertically */
  justify-content: center;        /* Center the items horizontally */
  align-items: center;            /* Center the items vertically */
  width: 100%;                    /* Full width */
  padding: 0 20px;                /* Padding to the left and right */
}

/* Side-by-side container that holds left and right containers */
.side-by-side-container {
  display: flex;                  /* Use Flexbox for layout */
  justify-content: space-between;  /* Space the containers evenly */
  width: 100%;                    /* Full width */
  max-width: 1200px;              /* Optional: maximum width for large screens */
  padding: 20px 0;                /* Padding between the containers */
}

/* Left and Right containers will be side by side */
.left-container, .right-container, .other-container {
  background-color: #B5DEF2;      /* Light background */
  border-radius: 8px;             /* Rounded corners */
  padding: 10px;                  /* Padding around the content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for the box */
  margin: 10px;                   /* Margin between left and right containers */
  transition: box-shadow 0.3s ease-in-out; /* Smooth shadow transition */
  flex: 1;                        /* Allow the containers to take up equal space */
  text-align: center;             /* Center text inside the containers */
}

/* Hover Effect for left container */
.left-container:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Slightly larger shadow when hovered */
}

/* Hover Effect for right container */
.right-container:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Slightly larger shadow when hovered */
}

.other-container:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Slightly larger shadow when hovered */
}

/* Button container style */
.button-container {
  display: inline-block; /* Makes the container shrink to fit the button */
  background-color: #9AA1AA; /* Light background */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Padding around the button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for the box */
  margin: 20px 0; /* Margin around the container */
  transition: box-shadow 0.3s ease-in-out; /* Smooth shadow transition */
}

/* Hover Effect for Button Container (Optional) */
.button-container:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Slightly larger shadow when hovered */
}

/* Main admin page layout */
.admin-page {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  padding: 20px;
  width: 100%;
  min-height: 100vh;
}

/* Wrapper for both left and right sections */
.admin-content-wrapper {
  display: flex;
  justify-content: space-between; /* Create space between the sections */
  width: 100%;
  max-width: 1200px; /* Max width for better responsiveness */
  gap: 30px; /* Space between left and right sections */
}

/* Left Section (Admin content) */
.left-section {
  background-color: #9AA1AA;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}

/* Right Section (Additional text/info) */
.right-section {
  flex: 2;
  background-color: #9AA1AA;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}

/* Bottom Section (Additional text/info) */
.bottom-section {
  flex: 1; /* Takes less space than the left section */
  background-color: #9AA1AA;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure it takes full width below the left and right sections */
  margin-top: 20px; /* Add some spacing between the sections */
}


/* Hover effects for sections */
.left-section:hover, .right-section:hover, .bottom-section:hover, .new-section:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Ensure the text inside the sections looks good */
h1, h2 {
  font-size: calc(20px + 1vmin);
  margin-bottom: 20px;
}

p {
  font-size: 1rem;
  line-height: 1.6;
}

p1 {
  font-size: 2rem;
  line-height: 1.6;
}

/* Adjust list style */
ul {
  list-style-type: none;
  padding-left: 0;
}

ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .admin-content-wrapper {
    flex-direction: column; /* Stack sections vertically */
    gap: 20px;
  }

  .left-section, .right-section, .bottom-section {
    width: 100%; /* Make sections full width */
    padding: 15px;
  }

  h1, h2 {
    font-size: calc(18px + 1vmin);
  }

  p {
    font-size: 1rem;
  }
}

.upload-wrapper {
  display: flex;                  /* Use Flexbox to layout the items in a row */
  justify-content: center;        /* Center the button-container */
  flex-direction: column;
  align-items: center;            /* Vertically center the content */
  width: 100%;                    /* Make sure it takes up the full width */
  padding: 0 20px;                /* Add some padding to the left and right */
  gap: 20px;
}

.left-upload, .right-upload {
  background-color: #9AA1AA;      /* Light background */
  border-radius: 8px;             /* Rounded corners */
  padding: 10px;                  /* Padding around the button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for the box */
  margin: 20px 100px;              /* Space between left/right containers and the button */
  transition: box-shadow 0.3s ease-in-out; /* Smooth shadow transition */
  flex: 0 0 auto;                 /* Prevent the containers from growing or shrinking */
}

.upload-container {
  background-color: #9AA1AA;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: auto; /* Ensure it takes full width below the left and right sections */
  margin-top: 20px; /* Add some spacing between the sections */
  margin-bottom: 20px;
}

.upload-container:hover, .right-upload:hover, .left-upload:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Style for the h2 elements to look like buttons */
.insight-button {
  background-color: #0056b3;
  color: white; /* White text */
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  margin: 15px 20px; /* 15px vertical, 20px horizontal margin for spacing between buttons */
  transition: background-color 0.3s, transform 0.2s;
}

.insight-button:hover {
  background-color: #3A98FC; /* Darker green on hover */
  transform: scale(1.05); /* Slight scale effect on hover */
}

.insight-button:focus {
  outline: none; /* Remove outline */
}

.insight-button:active {
  transform: scale(0.98); /* Scale down on click */
}

@media (max-width: 768px) {
  .chart-container {
      display: block;
  }

  .chart-container > div {
      margin-bottom: 20px;
  }
}

/* Basic styling for the preview window */
.preview-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-content h2 {
  margin: 0 0 10px;
}

.preview-container {
  margin-top: 20px;
}

.preview-content p {
  margin-bottom: 20px;
}

/* Close button inside preview */
.preview-content button {
  padding: 8px 16px;
  background-color: #323130;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}

.preview-content button:hover {
  background-color: #323130;
}

/* Basic styling for the action buttons */
.insight-button {
  cursor: pointer;
  font-size: 18px;
  margin: 10px;
  color: #007BFF;
}

.welcome-button {
  font-size: 18px;
  background-color: transparent;
  color: #3A98FC;
  border: none;
  cursor: pointer;
}

.welcome-button:hover {
  text-decoration: underline;
}

/* Welcome Menu Styles */
.welcome-menu {
  position: absolute; /* Keeps the menu fixed in the view */
  top: 50px; /* Adjust to be just below the welcome message button */
  right: 10px; /* Align it with the right side of the screen */
  background-color: #f8f8f8; /* Same as expandable-menu */
  border: 1px solid #ccc; /* Border style */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
  z-index: 10; /* Lower than menu toggle button, but still above other elements */
  width: 250px; /* Set menu width */
  max-width: 100%; /* Prevent overflow on smaller screens */
  max-height: 90vh; /* Prevent overflow from the viewport */
  overflow-y: auto; /* Allow vertical scrolling if content overflows */
}

/* Light mode and dark mode compatibility */
.welcome-menu.light-mode {
  background-color: #ffffff; /* White background for light mode */
}

.welcome-menu.dark-mode {
  background-color: #333333; /* Dark background for dark mode */
}

.welcome-menu ul {
  list-style: none; /* No bullets */
  padding: 10px;
  margin: 0;
}

.welcome-menu li {
  margin-bottom: 5px; /* Spacing between items */
}

.welcome-menu .button {
  cursor: pointer;
  padding: 1em;
  background-color: #3A98FC;
  color: white;
  border: none;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  transition: background-color 0.3s; /* Smooth hover effect */
}

/* Hover effect on button */
.welcome-menu .button:hover {
  background-color: #16306F;
}

/* For smaller screens, make the menu full-width */
@media (max-width: 768px) {
  .welcome-menu {
    width: 100%; /* Full width on small screens */
    max-width: 100%; /* Prevent overflow */
  }
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
}

.App-footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 14px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  margin-top: 10px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Sidebar Styles */
.sidebar {
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  transition: width 0.3s;
  overflow-x: hidden;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.sidebar.closed {
  width: 100px; /* Adjust for collapsed menu */
}

.sidebar-toggle {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  color: white;
  background-color: #444;
  border: none;
  margin-bottom: 20px;
}

.sidebar-item {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
}

.sidebar-item:hover {
  background-color: #555;
}

.sidebar-item .icon {
  margin-right: 10px;
}

.sidebar-item .title {
  display: inline-block;
  vertical-align: middle;
}

.main-content {
  margin-left: 250px; /* Make room for sidebar */
  transition: margin-left 0.3s;
}

.sidebar.open + .main-content {
  margin-left: 60px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1; /* Push Profile and Sign Out to the bottom */
}

.sidebar ul li {
  margin-bottom: 10px;
}